import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  SvgIcon,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  ListItemButton,
} from "@mui/material";
import NetPeaceLogo from "../../../static/images/NetPeaceDark.png";
import { ReactComponent as Logo } from "../../../static/svgs/LogoDark.svg";
import { ReactComponent as AccountIcon } from "../../../static/svgs/AccountIcon.svg";
import { ReactComponent as HamburgerIcon } from "../../../static/svgs/HamburgerIcon.svg";
import { ReactComponent as HomeIcon } from "../../../static/svgs/HomeIcon.svg";
import { ReactComponent as CompanyIcon } from "../../../static/svgs/CompanyIcon.svg";
import { ReactComponent as ProductIcon } from "../../../static/svgs/ProductIcon.svg";
import { ReactComponent as ContactIcon } from "../../../static/svgs/ContactIcon.svg";
import { ReactComponent as PricingIcon } from "../../../static/svgs/PricingIcon.svg";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Close, MenuBook } from "@mui/icons-material";

const Navbar = () => {
  const { access_token } = useSelector((state) => state.auth);
  const { user_data } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activePage, setActivePage] = useState("/home");
  const isMobile = useMediaQuery("(max-width:850px)");

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    navigate("logout/");
  };

  const handleLogin = () => {
    navigate("login/");
    setAnchorEl(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const navLinkStyle = (path) => ({
    color: activePage === path ? "#123991" : "black",
    fontFamily: "Quantico",
    fontSize: "small",
    fontWeight: 600,
    position: "relative",
    "::after": {
      content: '""',
      display: activePage === path ? "block" : "none",
      width: "65%",
      height: "2px",
      backgroundColor: "#123991",
      position: "absolute",
      bottom: 0,
      left: 1,
    },
  });

  const NavLink = ({ to, children }) => (
    <Typography component={Link} to={to} variant="h5" sx={navLinkStyle(to)}>
      {children}
    </Typography>
  );

  const drawerItems = [
    {
      icon: (
        <SvgIcon
          component={HomeIcon}
          sx={{ fontSize: 25, fill: "#123991" }}
          viewBox="0 0 24 24"
        />
      ),
      text: "HOME",
      path: "/home/",
      access: access_token ? false : true,
    },
    {
      icon: (
        <SvgIcon
          component={CompanyIcon}
          sx={{ fontSize: 25, fill: "#123991" }}
          viewBox="0 0 25 22"
        />
      ),
      text: "COMPANY",
      path: "/company/",
      access: access_token ? false : true,
    },
    {
      icon: (
        <SvgIcon
          component={ProductIcon}
          sx={{ fontSize: 25, fill: "#123991" }}
          viewBox="0 0 24 24"
        />
      ),
      text: "PRODUCT",
      path: "/product-detail/",
      access: access_token ? false : true,
    },
    {
      icon: (
        <SvgIcon
          component={ContactIcon}
          sx={{ fontSize: 25, fill: "#123991" }}
          viewBox="0 0 24 21"
        />
      ),
      text: "CONTACT US",
      path: "/contact-us/",
      access: access_token ? false : true,
    },
    {
      icon: (
        <SvgIcon
          component={PricingIcon}
          sx={{ fontSize: 25, fill: "#123991" }}
          viewBox="0 0 30 30"
        />
      ),
      text: "PRICING",
      path: "/pricing/",
      access: true,
    },
    {
      icon: <MenuBook sx={{ fontSize: 25, color: "white" }} />,
      text: "CASE STUDY",
      path: "/case-study/",
      access: access_token ? false : true,
    },
  ];

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
      <Toolbar>
        <Box
          component={Link}
          to="/"
          sx={{ display: "flex", gap: 0.5, textDecoration: "none" }}
        >
          <img src={NetPeaceLogo} className="w-[200px]" alt="Logo" />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ marginLeft: "auto" }}
            >
              <SvgIcon
                component={HamburgerIcon}
                sx={{ fontSize: 20, fill: "black" }}
                viewBox="0 0 20 20"
              />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              sx={{
                "& .MuiDrawer-paper": {
                  backgroundColor: "rgb(18,57,145,0.75)",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  padding: 2,
                },
              }}
            >
              <IconButton
                onClick={() => setDrawerOpen(false)}
                sx={{
                  marginLeft: "auto",
                  width: "fit-content",
                  background: "#123991",
                  marginBottom: 5,
                }}
              >
                <Close
                  sx={{
                    fontSize: "20px",
                    color: "white",
                  }}
                />
              </IconButton>
              <Box sx={{ width: 250 }}>
                <List>
                  {drawerItems
                    .filter((item) => item.access)
                    .map((item) => (
                      <ListItem disablePadding key={item.text}>
                        <ListItemButton
                          onClick={() => handleNavigation(item.path)}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText
                            sx={{
                              color: "white",
                              backgroundColor: "transparent",
                              "& .MuiTypography-root": {
                                fontFamily: "Quantico",
                              },
                            }}
                          >
                            {item.text}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Box>
              <Button
                fullWidth
                onClick={handleLogin}
                sx={{
                  marginTop: "auto",
                  background: "transparent",
                  marginBottom: 2,
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: 16,
                  color: "white",
                  textTransform: "none",
                  border: "2px solid white",
                  paddingY: 1.5,
                  borderRadius: "10px",
                }}
              >
                Login
              </Button>
            </Drawer>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                gap: 5,
                alignItems: "center",
                marginLeft: 8,
              }}
            >
              {!access_token ? (
                <>
                  <NavLink to="/home">HOME</NavLink>
                  <NavLink to="/company">COMPANY</NavLink>
                  <NavLink to="/product-detail">PRODUCT</NavLink>
                  <NavLink to="/case-study">CASE STUDY</NavLink>
                </>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {!access_token && <NavLink to="/contact-us/">CONTACT US</NavLink>}
              <NavLink to="/pricing/">PRICING</NavLink>
              <IconButton onClick={handleMenuClick}>
                <SvgIcon
                  component={AccountIcon}
                  sx={{ fontSize: 10 }}
                  viewBox="0 0 18 18"
                />
              </IconButton>
            </Box>
          </>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {access_token ? (
            <>
              <MenuItem onClick={handleMenuClose}>
                {user_data?.first_name}
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </>
          ) : (
            <MenuItem onClick={handleLogin}>Login</MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
