import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import SnackBar from "./Snackbar";
import { useSelector } from "react-redux";

const Layout = () => {
  // const { access_token } = useSelector((state) => state.auth);
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div className="sticky top-0 z-99">
        <SnackBar />
      </div>

      <Navbar />

      <div
        style={{ display: "flex", flexDirection: "column", flex: "1 0 auto" }}
      >
        <Outlet />
      </div>

      <div className="bottom-0 z-99">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
