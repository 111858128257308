import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../apiConfig";
import { getAccessToken } from "../jwtService";

export const contactus = createApi({
  reducerPath: "contactus",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_BASE_URL}/api/help/` }),
  endpoints: (builder) => ({

    addContactUs: builder.mutation({
      query: (params) => {
        return {
          url: "contact-us/",
          method: "POST",
          body: params.data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
  }),
});
export const { useAddContactUsMutation } = contactus;
