export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const clearLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user_data");
};


