import React, { Suspense, lazy, useCallback } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./components/Layout/Layout";
import NoAuthLayout from "./components/Layout/NoAuthLayout";
import logOut from "./services/auth/logout";
import { useEffect } from "react";
import "./static/css/NotFoundPage.css";
import "./index.css";

// Lazy-loaded components for code splitting
const NoAuthHomePage = lazy(() =>
  import("./components/NoAuthHomePage/NoAuthHomePage")
);
const HomePage = lazy(() => import("./components/HomePage/HomePage"));

const LoginSignupPage = lazy(() => import("./components/Auth/LoginSignupPage"));
const PricingPage = lazy(() => import("./components/PricingPlan/PricingPage"));
const OTPVerification = lazy(() =>
  import("./components/Auth/SignUpPage/OTPVerification")
);
const SignupConfirmation = lazy(() =>
  import("./components/Auth/SignUpPage/SignupConfirmation")
);

const ProductDetailsPage = lazy(() =>
  import("./components/ProductDetail/ProductDetail")
);

const ContactUs = lazy(() => import("./components/ContactUs/ContactUs"));

const CompanyPage = lazy(() => import("./components/Company/CompanyPage"));

const Loading = lazy(() => import("./components/Layout/Loading/Loading"));

const ErrorPage = lazy(() => import("./components/Layout/ErrorPage/ErrorPage"));
const CaseStudy = lazy(() => import("./components/CaseStudy/CaseStudy"));

const Support = lazy(() => import("./components/Support/Support"));

// PrivateRoute component for handling private routes
const PrivateRoute = ({ element }) => {
  const { access_token } = useSelector((state) => state.auth);

  const { user_data } = useSelector((state) => state.user);

  return access_token ? (
    user_data.is_email_verified ? (
      user_data.is_verified ? (
        element
      ) : (
        <Navigate to="/account-verfication/" /> 
      )
    ) : (
      element
    )
  ) : (
    <Navigate to="/login/" />
  );
};

const PrivateVerifyRoute = ({ element }) => {

  const { access_token } = useSelector((state) => state.auth);
  const { user_data } = useSelector((state) => state.user);

  return access_token ? (
    user_data.is_email_verified ? (
      user_data.is_verified ? (
        <Navigate to="/home/" /> 
      ) : (
        <Navigate to="/account-verfication/" /> 
      )
    ) : (
      element
    )
  ) : (
    <Navigate to="/login/" />
  );
};

const PrivateLoginRoute = () => {
  const { access_token } = useSelector((state) => state.auth);
  const { user_data } = useSelector((state) => state.user);

  return access_token ? (
    user_data.is_email_verified ? (
      user_data.is_verified ? (
        <Navigate to="/home/" /> 
      ) : (
        <Navigate to="/account-verfication/" /> 
      )
    ) : (
      <Navigate to="/verify-otp/" /> 
    )
  ) : (
    <LoginSignupPage />
  );
};

const PrivateLandingRoute = () => {
  return <Navigate to="/home" />;
};

const PrivateHomeRoute = () => {
  const { access_token } = useSelector((state) => state.auth);

  const { user_data } = useSelector((state) => state.user);


  return access_token ? ( user_data.subscription ? <HomePage /> : (<Navigate to="/pricing" />)) : <NoAuthHomePage />;
};

const NotFoundPage = () => {
  return <div className="not-found-page"></div>;
};

const PrivateLogoutRoute = () => {
  const { access_token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    if (access_token) {
      const logoutPath = await logOut(dispatch);
      navigate(logoutPath);
    } else {
      navigate("/login/");
    }
  }, [access_token, dispatch, navigate]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return null;
};

// ErrorBoundary component for error handling
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

const App = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<PrivateLandingRoute />} />
            <Route path="/home/*" element={<PrivateHomeRoute />} />

            <Route path="/support/*" element={<PrivateRoute element={<Support />} />} />

            <Route path="/pricing/*" element={<PricingPage />} />
            <Route path="/product-detail/*" element={<ProductDetailsPage />} />
            <Route
              path="/account-verfication/*"
              element={<SignupConfirmation />}
            />
            <Route path="/login/*" element={<PrivateLoginRoute />} />
            <Route path="/verify-otp/*" element={< PrivateVerifyRoute element={<OTPVerification />}/>} />
            <Route path="/contact-us/*" element={<ContactUs />} />
            <Route path="/logout/*" element={<PrivateLogoutRoute />} />

            <Route path="/company/*" element={<CompanyPage />} />
            <Route path="/case-study/*" element={<CaseStudy />} />
          </Route>

          {/* <Route element={<NoAuthLayout />}>
              
            </Route> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;