let env = "docker";

let API_BASE_URL;

if (env === "dev") {
  // Production environment
  API_BASE_URL = `http://127.0.0.1:5000`;
} else {
  // Local environment
  // API_BASE_URL = `http://${window._env_.REACT_APP_BACKEND_HOST}:${window._env_.REACT_APP_BACKEND_PORT}`;
  API_BASE_URL = `https://49.50.85.102:8001`;

}
export { API_BASE_URL, env };
