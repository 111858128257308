import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../apiConfig";
import { getAccessToken } from "../jwtService";

export const pricingApi = createApi({
  reducerPath: "pricingApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_BASE_URL}/api/subscriptions/` }),
  endpoints: (builder) => ({
    pricingDetails: builder.query({
      query: () => {
        return {
          url: "",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
    compareSubscription: builder.query({
      query: () => {
        return {
          url: "compare/",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
    getSubscription: builder.mutation({
      query: (data) => {
        return {
          url: "get-subscription/",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
    upgradeVersion: builder.mutation({
      query: (data) => {
        return {
          url: "upgrade-version/",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
    getTrial: builder.mutation({
      query: (data) => {
        return {
          url: "get-trial/",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
  }),
});
export const {
  usePricingDetailsQuery,
  useCompareSubscriptionQuery,
  useGetSubscriptionMutation,
  useUpgradeVersionMutation,
  useGetTrialMutation,
} = pricingApi;
