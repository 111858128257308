import { unSetAccessToken, unSetRefreshToken } from "../../features/authSlice";
import { openSnackbar } from "../../features/snackbarSlice";
import { unSetUserData } from "../../features/userSlice";
import { clearLocalStorage } from "../jwtService";


const logOut = async (dispatch) => {
  try {
    clearLocalStorage();
    dispatch(unSetAccessToken());
    dispatch(unSetRefreshToken());
    dispatch(unSetUserData());

    dispatch(
      openSnackbar({
        message: "Logged out successfully !",
        severity: "success",
      })
    );

    return '/login/'; // Return the path string instead of <Navigate> component
  } catch (error) {
    console.error("Logout failed:", error);
    dispatch(
      openSnackbar({
        message: "Logged out failed !",
        severity: "error",
      })
    );

    return '/home/'; // Return the path string instead of <Navigate> component
  }
}

export default logOut;
