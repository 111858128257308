import React from "react";
import { Avatar, Box, Divider, Grid, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../../static/svgs/Logo.svg";
import linkedInLogo from "../../../static/images/linkedin-logo.png";
import facebookLogo from "../../../static/images/Facebook_Logo.png";
import twitterLogo from "../../../static/images/twitter-logo.png";
import { useNavigate } from "react-router-dom";
import NetPeaceLogo from "../../../static/images/NetPeaceLight.png";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    const links = {
      linkedin: "https://www.linkedin.com/company/netpeace-technologies/",
    };
    window.open(links[link], "_blank", "noopener,noreferrer");
  };
  return (
    <Box sx={{ p: 3, bgcolor: "#123991", maxWidth: "100%" }}>
      <Grid container spacing={0}>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: 2,
              height: { xs: "55px", sm: "fit-content" },
            }}
          >
            <Box sx={{ display: "flex", gap: 0.5 }}>
              <img src={NetPeaceLogo} className="w-[200px]" alt="Logo" />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Avatar
                alt="Linkedin Logo"
                onClick={() => {
                  handleLinkClick("linkedin");
                }}
                src={linkedInLogo}
                sx={{ width: 25, height: 20, cursor: "pointer" }}
              />
              <Avatar
                alt="Facebook Logo"
                src={facebookLogo}
                sx={{ width: 20, height: 20 }}
              />
              <Avatar
                alt="Twitter Logo"
                src={twitterLogo}
                sx={{ width: 20, height: 20 }}
              />
            </Box>
          </Box>
          <Grid container spacing={0} sx={{ marginY: 4, paddingX: 5 }}>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                paddingTop: "0px !important",
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "medium",
                  fontWeight: 600,
                }}
              >
                Resources
              </Typography>
              <Typography
                variant="h5"
                onClick={() => navigate("/case-study/")}
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "small",
                  fontWeight: 200,
                  cursor: "pointer",
                }}
              >
                Case study
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                paddingTop: "0px !important",
                alignItems: { xs: "center", sm: "flex-start" },
                marginY: { xs: 2, sm: 0 },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "medium",
                  fontWeight: 600,
                }}
              >
                Company
              </Typography>
              <Typography
                variant="h5"
                onClick={() => navigate("/company/")}
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "small",
                  fontWeight: 200,
                  cursor: "pointer",
                }}
              >
                About Us
              </Typography>
              {/* <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "small",
                  fontWeight: 200,
                }}
              >
                Customers
              </Typography> */}
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "small",
                  fontWeight: 200,
                }}
              >
                Careers
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                paddingTop: "0px !important",
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "medium",
                  fontWeight: 600,
                }}
              >
                Support
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "small",
                  fontWeight: 200,
                  wordBreak: "break-word",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                New Delhi, Delhi, IN info@netpeace.in
              </Typography>
            </Grid>
          </Grid>
          <Divider flexItem sx={{ borderBottomColor: "white" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontSize: "medium",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              © 2024 NetPeace Technologies All Rights Reserved.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
