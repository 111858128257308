import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../apiConfig";
import { getAccessToken } from "../jwtService";

export const userAuthApi = createApi({
  reducerPath: "userAuthApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_BASE_URL}/api/user/` }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (params) => {
        return {
          url: "login/",
          method: "POST",
          body: params.data,
        };
      },
    }),
    signUp: builder.mutation({
      query: (data) => {
        return {
          url: "sign-up/",
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: builder.mutation({
      query: (data) => {
        return {
          url: "verify-otp/",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
    resendOtp: builder.mutation({
      query: () => {
        return {
          url: "resend-otp/",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        };
      },
    }),
    getCountries: builder.query({
      query: () => {
        return {
          url: "get-countries/",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});
export const {
  useLoginMutation,
  useSignUpMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useGetCountriesQuery,
} = userAuthApi;
