import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userAuthApi } from "../services/auth/userAuthApi";
import authReducer from "../features/authSlice";
import userReducer from "../features/userSlice";
import snackbarReducer, { openSnackbar } from "../features/snackbarSlice";
// import navigationReducer, { navigateTo } from "../features/navigationSlice";
import { pricingApi } from "../services/subscriptions/pricingApi";
import { contactus } from "../services/helpdesk/contactus";

// const errorMiddleware = (store) => (next) => (action) => {
//   if (action.payload?.status === 403) {
//     if (
//       action?.payload?.data?.error === "Please verify your email." ||
//       "Email not verified. OTP sent to your email"
//     ) {
//       store.dispatch(navigateTo("/verify-otp/"));
//     }
//     store.dispatch(
//       openSnackbar({
//         message: action.payload.data.error,
//         severity: "error",
//       })
//     );
//   } else if (action.payload?.status === 401) {
//     store.dispatch(
//       openSnackbar({
//         message: action.payload.data.error,
//         severity: "error",
//       })
//     );
//     store.dispatch(navigateTo("/sign-out/"));
//   }
//   return next(action);
// };

// const middlewares = [errorMiddleware];
const middlewares = [];

export const store = configureStore({
  reducer: {
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [pricingApi.reducerPath]: pricingApi.reducer,
    [contactus.reducerPath]: contactus.reducer,
    auth: authReducer,
    user: userReducer,
    snackbar: snackbarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        userAuthApi.middleware,
        pricingApi.middleware,
        contactus.middleware
      )
      .concat(middlewares),
});

setupListeners(store.dispatch);
